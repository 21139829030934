import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { render } from 'react-dom';
import { hot } from 'react-hot-loader/root';
import { MemoryRouter } from 'react-router-dom';

import { GlobalStateProvider } from 'globalState';

import App from 'components/App/App';

require('styles/index.scss');
require('styles/project/_fonts.scss');

const renderHotHash = Component => {
  render(
    <GlobalStateProvider>
      <MemoryRouter>
        <Component />
      </MemoryRouter>
    </GlobalStateProvider>,
    document.getElementById('app')
  );
};

renderHotHash(hot(App));
