/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import GridBlock from 'components/GridBlock/GridBlock';
import { Trail, config } from 'react-spring/renderprops';

import gridInput from '../../assets/json/grid-data.json';
import styles from './grid.module.scss';

const Grid = () => {
  const [gridData, setGridData] = useState([]); // new

  const importAll = r => {
    const res = r.keys().map(r);
    const arr = r.keys();
    // add pre-hashed imageURL as "name" prop on Module object
    res.map((item, i) => (item.name = arr[i]));
    return res;
  };

  const images = importAll(
    require.context('../../assets/images/', false, /\.(png|jpe?g|svg)$/)
  );

  useEffect(() => {
    fetch(gridInput)
      .then(res => res.json())
      .then(data => {
        setGridData([...data].reverse());
      })
      .catch(console.log);
    return () => {};
  }, []);
  const findImage = name => {
    return images.filter(item => item.name === `./${name}`)[0].default;
  };

  return (
    <div className={styles.wrapper}>
      <Trail
        items={gridData}
        keys={grid => grid.id}
        from={{ opacity: 0, transform: 'translateY(50px)' }}
        to={{ opacity: 1, transform: 'translateY(0)' }}
        config={config.default}
      >
        {grid => props => (
          <span style={props}>
            <GridBlock
              key={grid.id}
              src={findImage(grid.image)}
              title={grid.title}
              description={grid.description}
              linkTo={grid.link}
            />
          </span>
        )}
      </Trail>
    </div>
  );
};

export default Grid;
