import React, { useState } from 'react';
import clsx from 'clsx';
import { hasTopbar, hasTopbarMobile, theme, title } from 'settings';
import { config, Spring } from 'react-spring/renderprops';
import PageGrid from 'pages/PageGrid/PageGrid';
import TopBar from 'components/TopBar/TopBar';
import Circle from '../../assets/svg/circle.svg';
import Triangle from '../../assets/svg/triangle.svg';
import Rectangle from '../../assets/svg/rectangle.svg';

import styles from './app.module.scss';

const App = () => {
  const [startAnimation, setAnimation] = useState(false);

  const onAnimDone = () => {
    setAnimation(true);
  };

  return (
    <div
      className={clsx(styles.wrapper, `${theme}-theme`, {
        [styles.hasTopbar]: hasTopbar,
        [styles.hasTopbarMobile]: hasTopbarMobile,
      })}
    >
      {!startAnimation ? (
        <Spring
          from={{ opacity: 1 }}
          to={{ opacity: 0 }}
          delay={300}
          onRest={onAnimDone}
          config={config.slow}
        >
          {props => (
            <div style={props}>
              <div className={styles.loadingLogo}>
                <Rectangle />
                <Circle />
                <Triangle />
              </div>
            </div>
          )}
        </Spring>
      ) : (
        <>
          <TopBar title={title} />
          <PageGrid />
        </>
      )}
    </div>
  );
};

export default App;
