import React from 'react';
import { string } from 'prop-types';
// import { NavLink } from 'react-router-dom';

import styles from './gridBlock.module.scss';

const GridBlock = ({ title, description, linkTo, src }) => {
  return (
    <a href={linkTo} className={styles.wrapper}>
      <img className={styles.image} src={src} alt="" />
      <div className={styles.content}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.line} />
        <h2 className={styles.description}>{description}</h2>
      </div>
    </a>
  );
};

GridBlock.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  linkTo: string.isRequired,
  src: string.isRequired,
};

export default GridBlock;
