import React, { useState } from 'react';
import { string } from 'prop-types';
// import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import Logo from 'assets/svg/group.svg';

import styles from './topBar.module.scss';
import useDocumentScrollThrottled from '../useDocumentScrollThrottled';

const TopBar = ({ title }) => {
  const [transparentTopbar, setTransparentTopbar] = useState(false);

  const MINIMUM_SCROLL = 50;
  const TIMEOUT_DELAY = 300;

  useDocumentScrollThrottled(callbackData => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    setTimeout(() => {
      setTransparentTopbar(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.transparentMenu]: transparentTopbar,
      })}
    >
      <div className={styles.wrapperLogo}>
        <span className={styles.title}>{title}</span>
        <Logo height={16} width={12} className={styles.logo} />
      </div>
      <span className={styles.right}>
        Coded by{' '}
        <a className={styles.link} href="https://hoy.se">
          Hoy.se
        </a>
      </span>
    </div>
  );
};

TopBar.propTypes = {
  title: string,
};

TopBar.defaultProps = {
  title: '',
};

export default TopBar;
