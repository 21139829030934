const version = 1.0;

export const theme = 'dark'; // light or dark
export const title = 'Hoy Labs';

const displayTopBar = false;
const displayTopbarMobile = false;

export const hasTopbar = displayTopBar;
export const hasTopbarMobile = displayTopbarMobile;

export default {
  version,
  theme,
  title,
  hasTopbar,
  hasTopbarMobile,
};
